<template>
  <li class="ServiceOption">
    <div class="ServiceOption__icon">
      <ImageComponent :src="src" :alt="title" />
    </div>
    <div class="ServiceOption__container">
      <div class="ServiceOption__content">
        <h2 class="ServiceOption__title">{{ title }}</h2>
        <Price :value="total" />
      </div>
      <Select :items="quantityOptions" :action="value => (currentQuantity = Number(value))" />
    </div>
    <div class="ServiceOption__checkboxWrapper">
      <input type="checkbox" class="ServiceOption__checkbox" v-model="isSelected" />
    </div>
  </li>
</template>

<script>
import { postItems } from '@services/thansen/cart';
import ImageComponent from '@components/ImageComponent';
import Price from '@components/Price';
import Select from '@components/Select';

export default {
  name: 'ServiceOption',

  components: {
    ImageComponent,
    Price,
    Select,
  },

  model: {
    event: 'change',
  },

  props: {
    itemNumber: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      required: true,
    },
    quantity: {
      type: Number,
      default: 4,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentQuantity: this.quantity,
      isSelected: this.selected,
    };
  },

  computed: {
    total() {
      return this.price * this.currentQuantity;
    },
    quantityOptions() {
      return [1, 2, 3, 4].map(quantity => ({
        value: quantity,
        label: quantity + ' stk',
        isRefined: quantity === this.currentQuantity,
      }));
    },
  },

  watch: {
    currentQuantity(to, from) {
      if (to !== from && this.isSelected) this.updateCart();
    },
    isSelected: {
      immediate: true,
      handler(to, from) {
        if (from !== undefined) {
          this.updateCart();
        }
        this.$emit('change', to);
      },
    },
  },

  methods: {
    updateCart() {
      const item_number = this.itemNumber;
      const quantity = this.isSelected ? this.currentQuantity : 0;
      postItems([{ item_number, quantity }]);
    },
  },
};
</script>

<style lang="scss" scoped>
.ServiceOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  background: white;
  border-radius: $border-radius;
  @include maxBreakpoint($tablet-numeric) {
    padding: 10px;
  }

  .ServiceOption__icon {
    width: 150px;
    > i {
      font-size: 120px;
    }
    @include maxBreakpoint($tablet-numeric) {
      width: 80px;
      > i {
        font-size: 64px;
      }
    }
  }

  .ServiceOption__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    @include maxBreakpoint($tablet-numeric) {
      flex-direction: column;
      align-items: initial;
    }
  }

  .ServiceOption__content {
    display: flex;
    flex-direction: column;
  }

  .ServiceOption__title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
  }

  .ServiceOption__checkboxWrapper {
    padding: 10px;
  }

  .ServiceOption__checkbox {
    cursor: pointer;
    width: 25px;
    height: 25px;
    @include maxBreakpoint($tablet-numeric) {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
