<template>
  <div :class="['ServiceSelect', locked && 'ServiceSelect--locked']">
    <ul class="ServiceSelect__options">
      <ServiceOption
        v-for="service in bookingServices"
        :key="service.id"
        :itemNumber="service.product.item_number"
        :title="service.name"
        :src="service.icon"
        :price="service.product.price"
        :quantity="service.quantity"
        :selected="service.selected"
        v-model="selected[service.id]"
      />
    </ul>
    <div v-if="!locked" class="ServiceSelect__controls">
      <Button v-if="cancleable" type="light" @click="$emit('cancle')">{{
        $t('booking.services.wheels.no_thanks')
      }}</Button>
      <Button :disabled="!hasSelection" @click="$emit('submit')">{{ $t('choose') }}</Button>
    </div>
  </div>
</template>

<script>
import { Button, sizes as ButtonSizes } from '@components/Buttons';
import ServiceOption from './components/ServiceOption';

export default {
  name: 'ServiceSelect',

  components: {
    Button,
    ServiceOption,
  },
  props: {
    cancleable: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },

  enums: {
    ButtonSizes,
  },

  data() {
    return {
      selected: {},
      bookingServices: window.bookingServices,
    };
  },

  computed: {
    hasSelection() {
      return Object.values(this.selected).some(option => !!option);
    },
  },
};
</script>

<style lang="scss" scoped>
.ServiceSelect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 0;

  &--locked {
    cursor: default;
    pointer-events: none;
    opacity: 0.6;
  }

  .ServiceSelect__options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  .ServiceSelect__controls {
    display: flex;
    gap: 20px;
    width: 100%;
    > * {
      flex: 1;
    }
  }
}
</style>
