<template>
  <ModalDialog
    ref="modal"
    title="Udlevering uden montering"
    :working="working"
    confirmLabel="Bestil"
    @confirm="finalizeBooking"
  >
    Du er ved at bestille udlevering uden montering. Efter at have klikket bestil vil du modtage en
    sms bekræftelse og senere et tidspunkt for afhentning.
  </ModalDialog>
</template>

<script>
import { ModalDialog } from '@components/Modal';
import { patchWheelStorageBooking } from '@services/thansen/bookings';

export default {
  name: 'FinalizeDialog',

  components: {
    ModalDialog,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      working: false,
    };
  },

  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    finalizeBooking() {
      this.working = true;
      patchWheelStorageBooking(this.data.token, { is_finalized: true })
        .then(response => {
          window.location.replace('/');
        })
        .catch(errors => {
          window.location.replace('/');
        });
    },
  },
};
</script>
