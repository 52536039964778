<template>
  <div class="EditBookingButton">
    <Button
      :size="ButtonSizes.SMALL"
      :color="ButtonColors.WHITE"
      :icon="ButtonIcons.PEN"
      @click="$refs.modal.open()"
    >
      Ret udlevering
    </Button>
    <Modal ref="modal" title="Ret udlevering" :working="working">
      <template #default>
        <p>
          Du er ved at rette udlevering af:
          <br />
          <strong>{{ tireDescription }}</strong>
        </p>
        <h3 class="EditBookingButton__headline">Vælg udleveringssted:</h3>
        <StoreFinder
          class="EditBookingButton__storefinder"
          scrollable
          :onUpdate="setBranch"
          :preselect="{ slug: data.pickup_point.slug }"
          showAlerts
        />
      </template>
      <template #controls>
        <Button :disabled="disableSaveButton" @click="submit">Gem</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  Button,
  sizes as ButtonSizes,
  colors as ButtonColors,
  icons as ButtonIcons,
} from '@components/Buttons';
import { Modal } from '@components/Modal';
import StoreFinder from '@components/StoreFinder';
import { patchWheelStorageBooking } from '@services/thansen/bookings';

export default {
  name: 'EditBookingButton',

  components: {
    Modal,
    StoreFinder,
    Button,
  },

  enums: {
    ButtonSizes,
    ButtonColors,
    ButtonIcons,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      working: false,
      branch: null,
    };
  },

  computed: {
    wheelStorage() {
      return this.data.details;
    },
    disableSaveButton() {
      return this.branch === null || this.branch.slug === this.data.pickup_point.slug;
    },
    tireDescription() {
      return `${this.$tc('n_pcs', this.wheelStorage.quantity)} ${this.wheelStorage.tire.brand} ${
        this.wheelStorage.tire.measurement
      } (${this.wheelStorage.tire.type.toLowerCase()})`;
    },
  },

  methods: {
    submit() {
      this.working = true;
      patchWheelStorageBooking(this.data.token, { pickup_point: this.branch })
        .then(response => {
          this.gotoBooking();
        })
        .catch(errors => {
          this.working = false;
        });
    },
    setBranch(branch) {
      this.branch = branch;
    },
    gotoBooking() {
      window.location.replace('/booking');
    },
  },
};
</script>

<style lang="scss" scoped>
.EditBookingButton {
  .EditBookingButton__storefinder {
    min-height: 300px;
    height: 40vh;
  }

  .EditBookingButton__headline {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 1em;
  }
}
</style>
