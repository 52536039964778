<template>
  <Validator ref="validator" v-slot="{ valid, showErrors }">
    <Form
      :locked="working"
      class="WheelStorageLookupForm"
      @submit="valid ? submit() : showErrors()"
    >
      <template #header>
        <FadeTransition :duration="0.2" :delay="0" transform-height>
          <Alert
            v-if="showLoginError"
            :level="AlertLevel.ERROR"
            closeable
            class="WheelStorageLookupForm__alert"
          >
            Ugyldig opbevarings nr. eller mobilnummer
          </Alert>
        </FadeTransition>
      </template>
      <template #default>
        <Input
          id="storageId"
          name="storageId"
          :label="$t('wheel_storage.storage_id')"
          required
          validateOnBlur
          v-model="storageId"
        />
        <Input
          id="mobileNumber"
          name="mobileNumber"
          :label="$t('wheel_storage.mobile_number')"
          required
          validateOnBlur
          v-model="mobileNumber"
        />
      </template>
      <template #footer>
        <div class="WheelStorageLookupForm__controls">
          <Button :working="working">{{ $t('wheel_storage.lookup.controls.submit') }}</Button>
        </div>
      </template>
    </Form>
  </Validator>
</template>

<script>
import { postLookup, getWheelByToken } from '@services/thansen/wheelstorage';
import Validator from '@containers/Validator';
import Form from '@layouts/Form';
import FadeTransition from '@transitions/Fade';
import Alert, { AlertLevel } from '@components/Alert';
import { Button } from '@components/Buttons';
import Input from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'WheelStorageLookupForm',

  components: {
    Validator,
    Form,
    FadeTransition,
    Alert,
    Button,
    Input,
  },

  enums: {
    AlertLevel,
  },

  data() {
    return {
      storageId: '',
      mobileNumber: '',
      showLoginError: false,
      working: false,
      error: null,
    };
  },

  watch: {
    storageId() {
      this.hideError();
    },
    mobileNumber() {
      this.hideError();
    },
  },

  methods: {
    submit() {
      this.working = true;
      postLookup(this.storageId, this.mobileNumber)
        .then(response => {
          if (response && response.token) {
            getWheelByToken(response.token)
              .then(wheel => {
                this.working = false;
                this.$emit('result', wheel);
              })
              .catch(() => this.showError());
          } else {
            this.$emit('result', null);
            this.working = false;
          }
        })
        .catch(() => this.showError());
    },
    showError() {
      this.working = false;
      this.showLoginError = true;
    },
    hideError() {
      this.error = null;
      this.showLoginError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  transform: translate(0, 0);
  opacity: 1;
  transform-origin: left center;
  height: 30px;
}

.WheelStorageLookupForm {
  .WheelStorageLookupForm__alert {
    margin-bottom: 10px;
  }

  .WheelStorageLookupForm__controls {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
