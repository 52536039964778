<template>
  <div class="FindWheels">
    <div v-if="accountContainer.isLoggedIn" class="FindWheels__loggedIn">
      <WheelStorage v-slot="{ wheels, status }" :filter="wheel => wheel.status === '4'">
        <template v-if="status === 'FETCHED' || status === 'ERROR'">
          <ul v-if="wheels.length" class="FindWheels__accountWheelcards">
            <li v-for="wheel in wheels" :key="wheel.id" class="FindWheels__wheelcard">
              <WheelCard :data="wheel" />
            </li>
          </ul>
          <WheelStorageLookupForm v-else @result="result => (lookupResult = result)" />
        </template>
      </WheelStorage>
    </div>
    <div v-else-if="!accountContainer.isLoggedIn" class="FindWheels__findWheels">
      <WheelStorageLookupForm @result="result => (lookupResult = result)" />
      <div>
        <h4>Mit thansen</h4>
        <p>Log ind for at se de dæk du har liggende på vores dækhotel.</p>
        <Button
          :size="ButtonSizes.LARGE"
          class="FindWheels__loginButton"
          @click="$refs.modal.open(Modes.LOGIN)"
        >
          Log ind
        </Button>
        <LoginModal ref="modal" />
      </div>
    </div>
    <div v-if="lookupResult !== undefined" class="FindWheels__lookupResult">
      <WheelCard v-if="lookupResult !== null" :data="lookupResult" />
      <p v-else class="FindWheels__noResult">Fandt desværre ingen resultater</p>
    </div>
  </div>
</template>

<script>
import Collapse from '@transitions/Collapse';
import WheelStorage from '@scenes/SelfserviceScene/containers/WheelStorage';
import WheelCard from '@scenes/SelfserviceScene/scenes/WheelStorageScene/components/WheelStorageOverview/components/WheelCard2';
import WheelStorageLookupForm from './components/WheelStorageLookupForm';
import { Button, sizes as ButtonSizes } from '@components/Buttons';
import LoginModal, { Modes } from '@components/Login/components/LoginModal';

export default {
  name: 'FindWheels',

  components: {
    Collapse,
    WheelStorage,
    WheelCard,
    WheelStorageLookupForm,
    Button,
    LoginModal,
  },

  enums: {
    ButtonSizes,
    Modes,
  },

  data() {
    return {
      lookupResult: undefined,
    };
  },

  created() {},

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.FindWheels {
  .FindWheels__introButtons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .FindWheels__loggedIn {
    display: flex;
  }

  .FindWheels__accountWheelcards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }

  .FindWheels__wheelcard {
    flex: 1;
    min-width: 310px;
  }

  .FindWheels__findWheels {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap-reverse;
    column-gap: 20px;
    row-gap: 40px;
  }

  .FindWheels__loginButton {
    width: 100%;
  }

  .FindWheels__lookupResult {
    margin-top: 40px;
  }

  .FindWheels__noResult {
    font-size: 18px;
    color: #aeaeae;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
</style>
