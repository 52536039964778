<template>
  <AccountContainer v-slot="{}">
    <div class="WheelStorageBookingScene" v-show="status === Progress.COMPLETE">
      <h1>Booking</h1>

      <StepProgressBar
        class="WheelStorageBookingScene__progress"
        :alignment="StepAlignment.CENTER"
        :activeStep="progressBarStep"
        style="max-width: 700px; margin: 0 auto;"
        numbered
      >
        <Step id="type" title="Type" />
        <Step id="service" title="Service" />
        <Step id="time" title="Tid" />
      </StepProgressBar>

      <SectionCollapse
        v-if="$isEnabled(Features.WHEELSTORAGE)"
        title="Dækhotel"
        :collapsed="!display.wheelstorage"
      >
        <div v-if="!mode">
          <p>Har du dækhotel hos thansen og vil du have din opbevaring ud?</p>
          <div class="WheelStorageBookingScene__controls">
            <Button @click="mode = Mode.WHEELSTORAGE">Ja</Button>
            <Button @click="mode = Mode.SERVICES">Nej</Button>
          </div>
        </div>
        <div v-else-if="!activeBooking">
          <FindWheels />
          <div class="WheelStorageBookingScene__wheelStorageFooter">
            <Button :color="ButtonColor.WHITE" @click="mode = Mode.SERVICES">
              Fortsæt uden dækhotel
            </Button>
          </div>
        </div>
        <div v-else-if="activeBooking" class="WheelStorageBookingScene__wheelOverview">
          <p>Dæk til udlevering:</p>
          <BookingCard :data="activeBooking" editable class="WheelStorageBookingScene__wheel" />
        </div>
      </SectionCollapse>

      <SectionCollapse title="Services" ref="servicesSection" :collapsed="!display.services">
        <WheelSizeSelect
          v-if="display.wheelsize"
          :selected="size"
          :sizes="supportedRimSizes"
          style="margin-bottom: 20px;"
        />
        <ServiceSelect
          :cancleable="!!activeBooking"
          v-if="size"
          @submit="gotoTime"
          @cancle="() => $refs.finalizeDialog.open()"
        />
      </SectionCollapse>

      <SectionCollapse title="Tidspunkt" ref="timeSection" :collapsed="!display.time">
      </SectionCollapse>

      <FinalizeDialog v-if="activeBooking" ref="finalizeDialog" :data="activeBooking" />
    </div>
  </AccountContainer>
</template>

<script>
import { getWheelStorageBookings } from '@services/thansen/bookings';
import AccountContainer from '@containers/AccountContainer';
import Collapse from '@transitions/Collapse';
import BookingCard from '@scenes/BookingScene/components/BookingCard';
import { StepProgressBar, Step, StepAlignment } from '@components/StepProgressBar';
import FindWheels, { Responses } from './components/FindWheels';
import { Button, colors as ButtonColor } from '@components/Buttons';
import FinalizeDialog from './components/FinalizeDialog';
import Progress from '@types/Progress';
import ServiceSelect from '../../components/ServiceSelect';
import SectionCollapse from './components/SectionCollapse';
import WheelSizeSelect from './components/WheelSizeSelect';
import { gsap } from 'gsap/all';
import Features from '@types/Features';

const Mode = {
  WHEELSTORAGE: 'WHEELSTORAGE',
  SERVICES: 'SERVICES',
  TIME: 'TIME',
};

export default {
  name: 'WheelStorageBookingScene',

  components: {
    AccountContainer,
    Collapse,
    StepProgressBar,
    Step,
    BookingCard,
    FindWheels,
    FinalizeDialog,
    ServiceSelect,
    SectionCollapse,
    Button,
    WheelSizeSelect,
  },

  enums: {
    StepAlignment,
    ButtonColor,
    Responses,
    Progress,
    Mode,
    Features,
  },

  data() {
    return {
      bookings: null,
      activeBooking: null,
      status: '',

      mode: null,
      display: {
        wheelstorage: true, // wheelstorageSection
        services: false, // servicesSection
        wheelsize: false, // servicesSection
        time: false, // timeSection
      },
      supportedRimSizes: [14, 15, 16, 17, 18, 19, 20],
    };
  },

  watch: {
    mode() {
      this.updateDisplay();
    },
    activeBooking(to) {
      if (to) this.updateDisplay();
    },
  },

  computed: {
    progressBarStep() {
      if (this.mode === Mode.TIME) {
        return 'time';
      }
      return 'service';
    },
    activeBookingRimSize() {
      return this.activeBooking.details?.rim?.size || this.activeBooking.details?.tire?.size;
    },
    pickupPoint() {
      if (this.activeBooking) return this.activeBooking?.pickup_point;
      return null;
    },
    size() {
      let size = null;
      //if (this.activeBooking) rimSize = this.activeBooking.details?.rim?.size;
      if (window.bookingRimSize) size = window.bookingRimSize;
      else if (this.$route.query.size) size = this.$route.query.size;

      if (size) return Number(size);
      return null;
    },
  },

  created() {
    if (this.$route.query.type === 'time') {
      this.mode = Mode.TIME;
    } else if (this.$route.query.size || this.$route.query.type === 'service') {
      this.mode = Mode.SERVICES;
    } else if (!this.$isEnabled(Features.WHEELSTORAGE)) {
      this.mode = Mode.SERVICES;
    }
  },

  mounted() {
    if (this.$isEnabled(Features.WHEELSTORAGE)) {
      this.fetchWheelstorageBookings();
    } else {
      this.status = Progress.COMPLETE;
      setTimeout(this.show, 500);
    }
  },

  methods: {
    isRimSizeSupported(rimSize) {
      return this.supportedRimSizes.includes(Number(rimSize));
    },

    scrollToRef(ref) {
      if (this.$refs[ref] && this.$refs[ref].$el) {
        gsap.to(window, {
          duration: 0.7,
          delay: 0.2,
          scrollTo: { y: this.$refs[ref].$el, offsetY: 80 },
        });
      }
    },
    gotoTime() {
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, type: 'time' },
      });
      location.reload();
    },

    /** Updates which features is displayed */
    updateDisplay() {
      if (this.mode === Mode.SERVICES) {
        this.display.services = true;
        this.display.time = false;
      }

      if (this.mode === Mode.TIME) {
        this.display.time = true;
        this.display.services = true;
        if (this.pickupPoint) {
          $('#bookingselectbranch').hide();
          window.getStoreInformation({ slug: this.pickupPoint.slug }, 3);
        }
      }

      if (this.mode === Mode.WHEELSTORAGE) {
        this.display.wheelstorage = true;
        this.display.services = false;
        this.display.time = false;
      }

      if (!this.activeBooking) {
        this.display.wheelstorage = this.mode === Mode.WHEELSTORAGE;
      } else {
        this.display.wheelstorage = true;
      }

      if (this.display.services) {
        if (this.activeBooking) {
          if (this.activeBookingRimSize === undefined) this.display.wheelsize = true;
          else this.display.wheelsize = !this.isRimSizeSupported(this.activeBookingRimSize);
        } else if (!window.bookingFoundTire) {
          this.display.wheelsize = true;
        }
      }
    },

    fetchWheelstorageBookings() {
      this.status = Progress.WORKING;
      getWheelStorageBookings()
        .then(bookings => {
          this.bookings = bookings;
          const booking = this.bookings.find(booking => !booking.is_finalized);

          if (booking) this.setActiveBooking(booking);
          else this.show();

          this.status = Progress.COMPLETE;
        })
        .catch(error => {
          throw new Error(error);
          this.status = Progress.ERROR;
        });
    },

    setActiveBooking(booking) {
      if (booking) {
        const query = {};
        const rimType = booking.details?.type;
        const size = booking.details?.rim?.size || booking.details?.tire?.size;

        // Checks if rimsize isn't already in the query params
        if (!this.$route.query.size) {
          if (size !== undefined && this.isRimSizeSupported(size)) {
            query.size = size;
          }
        }

        if (!this.$route.query.tireOnly && rimType === 'Løse dæk') {
          query.tireOnly = 1;
        }

        if (!this.$route.query.type) {
          query.type = 'service';
        }

        if (Object.keys(query).length !== 0) {
          // Update route and reload if anything is added

          this.$router.replace({
            name: this.$route.name,
            query: { ...this.$route.query, ...query },
          });
          location.reload();
        } else {
          this.activeBooking = booking;
          this.show();
        }
      } else {
        this.show();
      }
    },
    show() {
      $('.Bookings').removeClass('hidden');
      switch (this.mode) {
        case Mode.TIME:
          this.scrollToRef('timeSection');
          break;
        case Mode.SERVICES:
          this.scrollToRef('servicesSection');
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.WheelStorageBookingScene {
  .WheelStorageBookingScene__wheelOverview {
    margin-bottom: 20px;
  }

  .WheelStorageBookingScene__wheel {
    display: flex;
  }

  .WheelStorageBookingScene__progress {
    padding: 20px;
  }

  .WheelStorageBookingScene__controls {
    display: flex;
    gap: 10px;
  }

  .WheelStorageBookingScene__wheelStorageFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    border-top: 1px solid $color-prim-grey;
    padding-top: 20px;
  }
}
</style>
