<template>
  <section :class="['SectionCollapse', collapsed && 'SectionCollapse--collapsed']">
    <h2 class="SectionCollapse__title ft-h2">{{ title }}</h2>
    <Collapse>
      <div class="SectionCollapse__content" v-if="!collapsed">
        <slot name="default"></slot>
      </div>
    </Collapse>
  </section>
</template>

<script>
import Collapse from '@transitions/Collapse';
export default {
  name: 'SectionCollapse',
  components: {
    Collapse,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.SectionCollapse {
  margin-bottom: 20px;
  background: $color-prim-grey-lighter;
  padding: 20px;

  .SectionCollapse__content {
    padding-top: 20px;
  }
  .SectionCollapse__title {
    margin-bottom: 0;
    transition: opacity 300ms;
  }
  &--collapsed .SectionCollapse__title {
    opacity: 0.6;
  }
}
</style>
