<template>
  <div>
    <div class="BookingCard">
      <div class="BookingCard__header">
        <div class="BookingCard__headline ft-h4">
          {{ wheelStorage.tire.brand }}
          {{ wheelStorage.tire.measurement }}
        </div>
        <TireSeasonLabel
          class="BookingCard__season"
          :text="wheelStorage.tire.type"
          :type="wheelStorage.tire.type_id"
        />
      </div>
      <div class="BookingCard__content">
        <div class="BookingCard__details">
          <ColonListItem :label="$t('quantity')" :value="$tc('n_pcs', wheelStorage.quantity)" />
          <ColonListItem :label="$t('wheel_storage.storage_id')" :value="wheelStorage.id" />
          <ColonListItem
            :label="$t('wheel_storage.order_number')"
            :urlPlaceholder="wheelStorage.order_number"
            :value="orderLink"
            :format="FormatTypes.URL"
            v-if="false"
          />
          <ColonListItem label="Udlevering" :value="data.pickup_point.name" />
        </div>
        <div v-if="editable" class="BookingCard__controls">
          <EditBookingButton :data="data" />
          <Button
            :size="sizes.SMALL"
            :color="colors.WHITE"
            :icon="icons.TRASH"
            @click="$refs.modal.open()"
          >
            Slet
          </Button>
          <ModalDialog
            ref="modal"
            title="Slet udlevering"
            :working="deleteWorking"
            confirmLabel="Slet"
            :confirmColor="colors.RED"
            @confirm="deleteBooking"
          >
            Er du sikker på at du vil slette denne udlevering?
          </ModalDialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TireSeasonLabel from '@scenes/SelfserviceScene/scenes/WheelStorageScene/components/WheelStorageOverview/components/WheelCard/components/TireSeasonLabel';
import { FormatTypes } from '@scenes/SelfserviceScene/components/ColonListItem';
import ColonListItem from '@scenes/SelfserviceScene/components/ColonListItem';
import { Button, sizes, colors, icons } from '@components/Buttons';
import { ModalDialog } from '@components/Modal';
import EditBookingButton from '../EditBookingButton';
import { deleteWheelStorageBooking } from '@services/thansen/bookings';

export default {
  name: 'BookingCard',

  components: {
    TireSeasonLabel,
    ColonListItem,
    Button,
    ModalDialog,
    EditBookingButton,
  },

  enums: {
    FormatTypes,
    sizes,
    colors,
    icons,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      deleteWorking: false,
    };
  },

  computed: {
    wheelStorage() {
      return this.data.details;
    },
    orderLink() {
      return this.$router.resolve({
        name:
          this.wheelStorage.request_method === 'lookup'
            ? '/link/order/:id'
            : '/selfservice/orders/:id',
        params: { id: this.wheelStorage.order_token },
      }).href;
    },
  },

  methods: {
    deleteBooking() {
      this.deleteWorking = true;
      deleteWheelStorageBooking(this.data.token)
        .then(response => {
          this.gotoBooking();
        })
        .catch(errors => {
          this.deleteWorking = false;
        });
    },
    gotoBooking() {
      window.location.replace('/booking');
    },
  },
};
</script>

<style lang="scss" scoped>
.BookingCard {
  border: 1px solid $color-prim-grey;
  border-radius: $border-radius;
  padding: 20px;
  background: white;
  .BookingCard__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2em;
  }

  .BookingCard__headline {
    font-weight: bold;
  }

  .BookingCard__content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
  }

  .BookingCard__details {
    font-size: 14px;
    display: flex;
    color: #878787;
    flex-direction: column;
  }

  .BookingCard__controls {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>
