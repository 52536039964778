<template>
  <div :class="['WheelSizeSelect', locked && 'WheelSizeSelect--locked']">
    <p>{{ $t('booking.services.wheels.choose_size') }}:</p>
    <div class="WheelSizeSelect__options">
      <button
        v-for="size in sizes"
        :key="size"
        :class="[
          'WheelSizeSelect__option',
          selected === size && 'WheelSizeSelect__option--selected',
        ]"
        @click.prevent="onSelected(size)"
      >
        {{ size }}"
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WheelSizeSelect',

  props: {
    sizes: {
      type: Array,
      default() {
        return [14, 15, 16, 17, 18, 19, 20];
      },
    },
    selected: {
      type: Number,
      default: null,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelected(size) {
      if (this.locked) return;
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, size },
      });
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.WheelSizeSelect {
  &--locked {
    cursor: default;
    pointer-events: none;
    opacity: 0.6;
  }
  .WheelSizeSelect__options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .WheelSizeSelect__option {
    padding: 20px;
    outline: none;
    border: none;

    font-size: 16px;
    font-weight: bold;

    background: white;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #f1f1f1;

    &--selected {
      color: white;
      background: $color-prim-blue;
    }
  }
}
</style>
